<template>
  <div class="Site">
    <SideBar index="0" :avatar="UserInfo.header != null && UserInfo.header.includes('head_')?`./static/head/${UserInfo.header}`:UserInfo.header"></SideBar>
    <!-- <div style="padding-left: 15px;padding-top: 10px">
      <img :src="`./yfc/logo.png`" width="100"></img>
    </div> -->

<!--    <div class="flex_sb flex_ac top_div">
      <div class="top_text">
        <van-icon name="./skin/logo.png" size="20"/>
      </div>
      <img class="top_img" src="~@/assets/img/new/kefu.png" alt="" @click="$router.push('serviceCenter')">
    </div>-->

    <div class="Home" style="position: relative; top: 50px;">

      <!-- start -->
        <van-swipe :autoplay="3000"
                   :show-indicators="false"
                   class="homeswipe">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img ref="img" :src="item" class="BroadBarItem" width="100%" height="80%"/>
            <!--<img src="~@/assets/img/home/11@3x.png"/>-->
          </van-swipe-item>
        </van-swipe>
      <!-- end -->

      <!-- start -->
      <div class="header_link flex_ac flex_sb">
        <img class="tz_img1" src="~@/assets/img/new/tz_img.png" alt="">
        <van-notice-bar
            background="none"
            :text="noticeBarContent"
            style="color: var(--bc-text-color)"
        />
        <div class="show-more" @click="gotoMsgList()"> {{$t('home.showmore')}}</div>
      </div>
      <!-- end -->

      <!-- start -->
      <div>
          <div style="height:192px;background:rgba(255,255,255,0);border:1px solid #707070;border-radius:20px;opacity:1;  margin: 10px 2%;padding: 10px 0;margin-top: 0px;">
            <div style="display: flex; flex-direction: row; height: 50%">
              <div style="width: 30%;">
                <img style="margin-left: 30px; width: 68px;height: 68px;" :src="`./static/currency/`+currency0Item.name.toLowerCase()+`.png`" alt="">
              </div>
              <div style="width: 70%; display: flex; flex-direction: row; align-items: center;">
                <div style="width: 50%;">
                  <div class="t_name"> {{currency0Item.name}}<span class="t_text"> / {{InitData.usdtinfo.sign}}</span></div>
                  <div style="font-size: 14px;font-weight: bold;margin:10px 0"> {{parseFloat(currency0Item.price).toFixed(2)}}</div>
                </div>
                <div style="width: 50%;margin-top: 21px;">
                  <div v-if="currency0Item.rate>0" style="color: #ffffff;font-size: 12px;">
                    <img style="width:10px;margin-right:6px" src="~@/assets/img/new/shang.png" alt="">
                    {{'+' + parseFloat(currency0Item.rate).toFixed(2)}}%
                  </div>
                  <div v-else style="color: #ffffff;font-size: 12px;">
                    <img style="width:10px;margin-right:6px" src="~@/assets/img/new/xia.png" alt="">
                    {{'-' + parseFloat(currency0Item.rate).toFixed(2)}}%
                  </div>
                </div>
                <div>
                  <img style="width:26px;margin-right:6px" src="~@/assets/img/home/right-jt.png" alt="" @click="nextPrice()">
                </div>
              </div>
            </div>
            <div>
              <div style="position: relative; z-index: 19; top:20px;height: 2px; border-top: 1px dashed #5F8057FF;border-dasharray: 5px 10px;"></div>
              <div style="position: relative;">
                <div class="overlay"></div>
                <div class="polygon"></div>
              </div>
            </div>
          </div>
      </div>

      <!-- end -->

      <!-- start -->
      <div class="trading">
        <div style="display: flex; height: 124px;">
          <img style="position: absolute; max-width: 50%; max-height: 50%; width: auto; height: auto; " src="~@/assets/img/home/tradingbg@3x.png" alt="">
          <img style="position: absolute; max-width: 96%; max-height: 96%; width: auto; height: auto;" src="~@/assets/img/home/tradingbg1@3x.png" alt="">
          <div style="position: absolute; left: 17px;">
            <div style="color: #ffffff; font-size: 12px; font-weight: bold; margin-top: 12px;"> {{$t('home.contents[0]')}} </div>
            <div style="width: 150px;font-size: 12px;  margin-top: 14px;">{{$t('home.contents[1]')}}</div>
          </div>
          <div style="position: absolute; right: 17px;">
            <div style="display: flex; justify-content: center;width: 100%; color: #ffffff; font-size: 12px; font-weight: bold; margin-top: 28px; text-align: center;">
              <div style="width: 74px; ">{{$t('home.contents[2]')}}</div>
            </div>
            <div style="width: 159px;font-size: 12px;  margin-top: 12px;">{{$t('home.contents[3]')}}</div>
          </div>
        </div>
      </div>
      <!-- end -->


      <!-- start -->
      <div class="trading" style="margin-top: 16px;">
        <div style="display: flex; height: 143px;">
          <img style="position: absolute; max-width: 96%; max-height: 96%; width: auto; height: auto; " src="~@/assets/img/home/background@3x.png" alt=""/>
          <div style="position: absolute; display: flex; width: 96%; flex-direction: column; align-items: center;">
            <div style="width: 20%"></div>
            <div style="margin-bottom: 24px; width:242px;font-size: 14px; font-weight: bold; margin-top: 12px; text-align: center;">
              {{$t('home.contents[4]')}}
            </div>
            <div style="width: 96%; display: flex; justify-content: space-between;">
              <div>
                <img style="position: absolute; max-width: 100%; max-height: 100%; width: auto; height: 53px; left: -52px;"
                     src="~@/assets/img/home/11769@3x.png" alt=""/>
              </div>
              <div class="gradient-panel-x" style="display:flex; padding-left: 10px;padding-right: 10px; width: 92%; height: 53px;padding-top: 9px;">
                <div style="text-align: center; width: 30%;">
                  <div style="font-size: 14px; font-weight: bold;">6 million+</div>
                  <div style="line-height:22px;font-size: 8px;">{{$t('home.contents[5]')}} </div>
                </div>
                <div style="text-align: center;">
                  <div style="font-size: 14px; font-weight: bold;">130+</div>
                  <div style="line-height:22px;font-size: 8px;">{{$t('home.contents[6]')}}</div>
                </div>
                <div style="text-align: center; margin-left: 4px;">
                  <div style="font-size: 14px; font-weight: bold;">$50B+</div>
                  <div style="line-height:22px; font-size: 8px;">{{$t('home.contents[7]')}}</div>
                </div>
              </div>
              <div>
              <img style="position: absolute; max-width: 100%; max-height: 100%; width: auto; height: 53px; right: -52px;"
                     src="~@/assets/img/home/11768@3x.png" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end -->

      <!-- start -->
      <div class="trading" style="display: flex;justify-content: center;">
        <div style="text-align: center;">
          <div>{{$t('home.contents[8]')}}</div>
          <div>{{$t('home.contents[9]')}}</div>
        </div>
      </div>
      <div class="trading" style="height:127px;">
        <div style="display: flex;justify-content: space-between;margin-top: -10px;">
          <div style="position: relative;">
            <div>
              <img style=""  src="~@/assets/img/home/11770.png" alt=""/>
            </div>
            <div style="position: absolute; top: 19px; left: 19px;">
              <img src="~@/assets/img/home/icon1.bece2ac8.png" alt=""/>
            </div>
          </div>

          <div style="position: relative;">
            <div>
              <img src="~@/assets/img/home/11770.png" alt=""/>
            </div>
            <div style="position: absolute; top: 19px; left: 19px;">
              <img src="~@/assets/img/home/icon2.ce56af08.png" alt=""/>
            </div>
          </div>

        </div>
        <div style="display: flex;justify-content: space-around;">
          <div style="position: relative; left: 12px;">
            <div>
              <img src="~@/assets/img/home/11770.png" alt=""/>
            </div>
            <div style="position: absolute; top: 19px; left: 19px;">
              <img src="~@/assets/img/home/icon3.19e87dbf.png" alt=""/>
            </div>
          </div>
          <div style="position: relative; right: 12px;">
            <div>
              <img src="~@/assets/img/home/11770.png" alt=""/>
            </div>
            <div style="position: absolute; top: 19px; left: 19px;">
              <img src="~@/assets/img/home/icon4.ac7aa1db.png" alt=""/>
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: space-around;position: relative; top: -64px;">
          <div>
            <img src="~@/assets/img/home/xianduan1.png" alt=""/>
          </div>
          <div style="margin-top: 20px;">
            <img src="~@/assets/img/home/xianduan.png" alt=""/>
          </div>
          <div>
            <img src="~@/assets/img/home/xianduan2.png" alt=""/>
          </div>
        </div>
        <div style="display: flex;justify-content: space-around;position: relative; top: -150px; font-size: 8px;">
          <div style="margin-left: 18px;">{{$t('home.contents[10]')}}</div>
          <div style="margin-right: 18px;">{{$t('home.contents[11]')}}</div>
        </div>
        <div style="display: flex;justify-content: space-around;position: relative; top: -52px; font-size: 8px;">
          <div style="margin-left: 18px;">{{$t('home.contents[12]')}}</div>
          <div style="margin-right: 18px;">{{$t('home.contents[13]')}}</div>
        </div>
      </div>
      <!-- end -->

      <!-- start -->
      <div class="homelist">
        <div class="title" style="display: flex;justify-content: space-around;">
          <div style="width: 56%;">
            <img src="~@/assets/img/skinbitcoin/market.png" style="width: 14px;height: 17px; margin-right: 6px;">
            {{$t('home.spot_market')}}
          </div>
          <div style="width: auto; display:flex; align-items: center; color:var(--bc-text-color); font-size: 12px;">
          <div style="margin-right: 16px;">{{$t('home.contents[14]')}}</div>
          <div><img src="~@/assets/img/home/19340@3x.png" style="height: 8px"></div>
          </div>
        </div>
        <div v-for="(item, index) in currency2" class="coinitem">
          <table style="font-size:13px;width: 100%;padding:8px 5px 7px 0px">
            <tr>
              <td style="width: 10%">
                <img :src="`./static/currency/`+item.name.toLowerCase()+`.png`" style="height: 2.5rem;margin-right:10px" />
              </td>
              <td style="font-size: 16px;width: 20%;">
                <div style="font-weight:bold;color: var(--font_nav_bar)">{{item.name}}</div>
                <div style="font-size: 12px" v-if="item.rate>0" class="rate1">+{{parseFloat(item.rate).toFixed(2)}}%</div>
                <div style="font-size: 12px" v-else class="rate2">{{parseFloat(item.rate).toFixed(2)}}%</div>
              </td>
              <td style="font-size: 16px;text-align: right">
                <div style="line-height: 20px">$ {{$Util.formatNum(parseFloat(item.price0).toFixed(2))}}
                </div>
                <div style="font-size: 11px" v-if="item.volume">Vol : {{$Util.formatNum(parseFloat(item.volume/1000).toFixed(2))}}k
                </div>
              </td>

            </tr>
          </table>
        </div>
      </div>
      <!-- end -->

      <!-- start -->
      <div class="trading">
        <div style="font-size: 12px;">{{$t('home.contents[15]')}}</div>
        <div style="position: absolute;"><img style="max-width: 100%; max-height: 100%; width: auto; " src="~@/assets/img/home/16@3x.png" alt=""/></div>
        <div style="position: absolute;">
          <div style="margin-top: 90px;">
            <!--<div class="gradient-text" style="font-size: 9px; width: 147px;">{{$t('home.contents[16]')}}</div>-->
            <div style="margin-top:8px; font-size: 9px; width: 184px;">{{$t('home.contents[17]')}}</div>
            <div>
              <div style="position: absolute;"><img style="margin-top: 8px; max-width: 100%; max-height: 100%; width: auto; " src="~@/assets/img/home/12622.png" alt=""/></div>
              <div style="position: relative; font-size: 8px; top: 14px; left: 18px;">{{$t('home.contents[18]')}}</div>
            </div>
          </div>
        </div>
        <div style="height: 168px;"></div>
      </div>
      <!-- end -->

      <!-- start -->
      <div class="trading" style="display: flex;justify-content: center;flex-direction: column; align-items: center;">
        <div style="text-align: center;">
          <div>{{$t('home.contents[19]')}}</div>
          <div>{{$t('home.contents[20]')}}</div>
        </div>
        <div style="text-align: center;">
          <div style="width: 333px; font-size: 10px; text-align: center; margin-top: 8px;">
            <div>{{$t('home.contents[21]')}}</div>
            <div>{{$t('home.contents[22]')}} </div>
          </div>
        </div>
      </div>
      <div>
        <div class="service">
          <img src="~@/assets/img/home/partners1.adc1873e.png" alt=""/>
        </div>
        <div class="trading border-grey service-content">
          <div style="text-align: center; margin-top: 88px;">
            <div style="font-size: 20px;">{{$t('home.contents[23]')}}</div>
            <div style="font-size: 14px; margin-top: 12px;">
              <div>{{$t('home.contents[24]')}}</div>
              <div>{{$t('home.contents[25]')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="service" >
          <img src="~@/assets/img/home/partners1.adc1873f.png" alt=""/>
        </div>
        <div class="trading border-grey service-content">
          <div style="text-align: center; margin-top: 88px;">
            <div style="font-size: 20px;">{{$t('home.contents[26]')}}</div>
            <div style="font-size: 14px; margin-top: 12px;">
              <div>{{$t('home.contents[27]')}}</div>
              <div>{{$t('home.contents[28]')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div class="service">
        <img src="~@/assets/img/home/partners1.adc1873g.png" alt=""/>
      </div>
      <div class="trading border-grey service-content">
        <div style="text-align: center; margin-top: 88px;">
        <div style="font-size: 15px;">{{$t('home.contents[29]')}}</div>
        <div style="font-size: 10px; margin-top: 12px;">
          <div>{{$t('home.contents[30]')}}</div>
          <div>{{$t('home.contents[31]')}}</div>
          <div>{{$t('home.contents[32]')}}</div>
        </div>
        </div>
      </div>
    </div>
      <!-- end -->

      <!-- start -->
      <div class="trading"
           style="display:grid ; grid-template-columns: 47% 47%; grid-row-gap: 20px; grid-column-gap: 20px; height: 208px;">
        <div class="border-grey" style="width: auto;height: 97px;">
          <div style="position: relative; font-size: 8px;">
            <div style="padding: 4px 4px;">
              <div>{{$t('home.contents[33]')}}</div>
              <div>{{$t('home.contents[34]')}}</div>
              <div style="font-size: 7px; margin-top: 2px;">{{$t('home.contents[35]')}}
              </div>
            </div>
            <div style="position: absolute; left: 140px; top: 4px;">
              <img src="~@/assets/img/home/19341.png" alt=""/>
            </div>
          </div>
        </div>
        <div class="border-grey" style="width: auto;height: 97px;">
          <div style="position: relative; font-size: 8px;">
            <div style="padding: 4px 4px;">
            <div>{{$t('home.contents[36]')}}</div>
              <div>{{$t('home.contents[37]')}}</div>
            <div style="font-size: 7px;margin-top: 2px;">
              {{$t('home.contents[38]')}}
            </div>
          </div>
          <div style="position: absolute; left: 130px; top: 4px; z-index: -1;">
            <img src="~@/assets/img/home/11772.png" alt="" />
          </div>
          </div>
        </div>
        <div class="border-grey" style="width: auto;height: 97px;">
          <div style="position: relative; font-size: 10px;">
          <div style="padding: 4px 4px;">
            <div style="position: relative; font-size: 8px;">{{$t('home.contents[39]')}}</div>
            <div style="font-size: 7px;margin-top: 24px;">{{$t('home.contents[40]')}}
            </div>
          </div>
          <div style="position: absolute; left: 140px; top: 4px;">
            <img src="~@/assets/img/home/19345.png" alt=""/>
          </div>
          </div>
        </div>
        <div class="border-grey" style="width: auto;height: 97px;">
          <div style="position: relative; font-size: 8px;">
          <div style="padding: 4px 4px;">
            <div style="position: relative; font-size: 12px;">{{$t('home.contents[41]')}}</div>
            <div style="font-size: 7px;margin-top: 14px;">
              {{$t('home.contents[42]')}}
            </div>
          </div>
          <div style="position: absolute; left: 140px; top: 4px;">
            <img src="~@/assets/img/home/19346.png" alt=""/>
          </div>
          </div>
        </div>
      </div>
      <!-- end -->

      <!-- start -->
      <div class="trading">
        <div style="text-align: center; font-size: 18px; padding: 20px">
          <div>{{$t('home.contents[43]')}}</div>
        </div>
      </div>
      <div class="trading border-grey" style="display: flex; justify-content: space-between;">
        <div style="margin-left: 6px;"> {{$t('home.contents[46]')}}</div>
        <div style="margin-right: 6px;"><img style="max-width: 100%; max-height: 100%; width: auto; " src="~@/assets/img/home/11782.png" alt=""/></div>
      </div>
      <div class="trading">
        <div style="text-align: center;">
          <div style="text-align: start; padding: 10px; font-size: 10px;">
            {{$t('home.contents[44]')}}
          </div>
        </div>
      </div>
      <div class="trading border-grey" style="display: flex; justify-content: space-between;">
        <div style="margin-left: 6px;">{{$t('home.contents[47]')}}</div>
        <div style="margin-right: 6px;"><van-icon name="add-o" /></div>
      </div>
      <div class="trading">
        <div style="text-align: center;">
          <div style="text-align: start; padding: 10px; font-size: 10px;">
            {{$t('home.contents[45]')}}
          </div>
        </div>
      </div>
      <!-- end -->

    </div>
    <div class="h80"></div>
<!--    <Footer />-->
    <van-popup
        v-model="showNotice"
        style="background: transparent; width: 90%; text-align: center"
        @closed="$parent.isNotice = true"
    >
      <div class="popupBg">
        <dl
            class="ScrollBox NoticePopup"
            v-if="InitData.noticelist && InitData.noticelist.length"
            @click="$router.push(`/article/notice/${InitData.noticelist[0].id}`)"
        >
          <dt class="popup_title">
            <div style="margin: 0 auto">
              <span :class="{ letter_s: language1 }">{{
                  $t("home.noticeTitle")
                }}</span
              ><span>NOTICE</span>
            </div>
          </dt>
          <dd v-html="InitData.noticelist[0].content" style="color: #000"></dd>
        </dl>
      </div>
      <a class="close" href="javascript:;" @click="showNotice = false">
        <img src="../../public/static/images/pupup_false.png" />
      </a>
    </van-popup>
  </div>
</template>


<script>
import $ from "jquery";
// import {ref} from 'vue'
export default {
  name: "Home",
  inject: ["reloadHtml"],
  components: {},
  props: ["user_id"],
  data() {
    return {
      currentRate: 0,
      showNotice: false,
      tabActive: 0, //任务大厅
      tabActive1: 0, //榜单
      currency0:[],
      currency0Index:0,
      currency0Item:{},
      currency2:[],
      noticeBarContent:"",
      language1: localStorage.getItem("Language") == "cn",
      bannerList: [
        //"../static/images/bg_banner.png",
          "../static/images/12633.png"
      ],
      moeny: "",
      currHeader:'head_6.png',
      timer: null, //定时器
      time1:0,
    };
  },

  computed: {
    text() {
      // return this.currentRate.toFixed(0) + '%'
      return "loading";
    },
  },
  watch: {
    "InitData.noticelist"(data) {
      if (!this.$parent.isNotice && data.length) this.showNotice = true;
    },
  },
  created() {
    /*
    if (!localStorage["Token"]) {
      this.$router.push("/login");
      return;
    }
     */
    var a = this.InitData;
    window.callReadFunction = this.callReadFunction;
    if (localStorage["Token"]) {
      // 查询绑定银行卡
      this.$Model.GetBankCardList();
    }
    var ret = this.$Util.readAppStorage();
    console.log("欢迎");
    this.noticeBarContent = "";
    this.$Model.GetBackData((data) => {
      // this.bannerList = data.bannerList;
      this.usdtinfo = data.usdtinfo;
      for (let i=0;i<data.drawNotice.length;i++){
        this.noticeBarContent += data.drawNotice[i]['content']+"; "
      }
      this.noticeBarContent = this.noticeBarContent.replace("<p>","").replace("</p>","")
    });
    this.getPrices();
    // alert("xxxxxxxxxxxxx:"+ret)
  },
  mounted() {
    if (this.UserInfo.header){
      this.currHeader = this.UserInfo.header;
    }
    //获取浏览器地址
    let getHref = window.location.href;
    // console.log(this.$refs)
    // for(let i=0;i<)
  },
  activated() {},
  destroyed() {},
  // 离开页面清除倒计时
  beforeDestroy() {
    clearInterval(this.timer);
    console.log("clear timer done:"+this.timer);
  },
  methods: {
    gotoBuy(){
      let usertype = this.UserInfo.user_type;
      if (!usertype||usertype==0){
        this.$Dialog.Toast(this.$t("busAuth2[5]"));
        return;
      }else{
        this.$router.push("/buy");
      }
    },
    callReadFunction(data) {
      localStorage["AppData"] = data;
      // this.$toast(data)
    },
    getPrices() {
      var that = this;
      that.setCoinData(that.InitData);
      setTimeout(function() {
        that.timer = setInterval(() => {
          // console.log("get prices");
          that.getCommonData();
        }, 4000);
      }, 5000);
    },
    getCommonData(){
      var that = this;
      that.time1 = new Date().getTime();
      this.$Model.GetCommonData(data=>{
        that.setCoinData(data)
      });
    },
    setCoinData(data){
      var that = this;
      let time2 = new Date().getTime();
      if (!data.currencylist){
        return;
      }
      console.log("time cost00: "+(time2-that.time1))
      let coins = data.currencylist;
      if (coins.length<3){
        this.$Dialog.Toast("no currency found");
        return;
      }
      console.log("get prices done:")
      let coins0 = [];
      for (var i=0;i<3;i++){
        coins0.push(coins[i]);
      }
      this.currency0 = coins0;
      coins.forEach(coin=>{
        var ii = "star1";
        var kk = Math.round(Math.random()*10);
        if (kk>5){
          ii = "star2"
        }
        coin['star'] = ii;
      })
      this.currency2 = coins;
      time2 = new Date().getTime();
      console.log("time cost: "+(time2-that.time1))

      this.currency0Item = this.currency0[this.currency0Index];
    },
    goTask(index, state) {
      if (state == 3) {
        this.$toast.fail(this.$t("home.msg"));
      } else {
        let grade = this.UserInfo.vip_level;
        let taskType = this.InitData.taskclasslist.filter(
            (obj) => obj.state == 1
        )[index].group_id;
        /* this.$router.push(`/taskList/${taskType}/${grade}`); */
        this.$router.push("/GrabOrders");
      }
    },

    // 打招呼
    goChatPage(id) {
      this.$router.push(`/user/chatPage/${id}`);
    },
    toGrabtask() {
      console.log(1);
      this.$router.push("/user/Grabtask");
    },
    nextPrice() {
      this.currency0Index ++;
      if(this.currency0Index == 3){
        this.currency0Index = 0;
      }
      this.currency0Item = this.currency0[this.currency0Index];
    },
    gotoMsgList() {
      this.$router.push("/msglist");
    },
  },
};
</script>
<style scoped lang="scss">
.Site {

  &::v-deep .van-swipe__indicators{
    left: auto;
    right:0 !important;
  }
  &::v-deep .van-swipe__indicator--active{
    width: 14px;
    border-radius: 3px;
  }
}
.top_div{
  width: 96%;
  margin-left: 2%;
  padding: 12px 0;
  .top_text{
    font-size: 18px;
  }
  .top_img{
    height: 20px;
    width: 20px;
  }
}

.ScrollBox{
  // background-color: #F5F8FF;
}
.BroadBarItem {
  width: 100%;
}


.header_link {
  //padding: 0 15px;
  margin: 0px 6px;
  margin-top: -32px;
  // border-radius: 10px;
  color: var(--font_color);
  background: var(--panel_color);
  border-bottom: 1px solid var(--bc-text-color-3);
  margin-bottom: 16px;

  .van-notice-bar{
    color: var(--font_color);
    width: calc(100% - 50px);
  }
  .tz_img1{
    width: 28px;
  }
  .tz_img2{
    width: 10px;
  }
}

.menuPanel {
  display: flex;
  margin: 10px auto;
  text-align: center;
  padding: 10px;
  width: 95%;
  margin-bottom: 0px;
  border-radius: 10px;
  font-size: 13px;
  background-color: var(--panel_color);
}
.Menu2 ::v-deep .van-grid-item__content {
  /*background-color: #151d31;*/
  border-radius: 5px;
  color:#cccccc;
  background: transparent none repeat scroll 0% 0%;
}

.tool ::v-deep .van-grid-item__content {
  padding: 3px !important;
  display: flex;
  color: #D7D7D7;
  background-color:var(--panel_color);
}

.homeswipe {
  border-radius: 10px;
  width: 96%;
  margin-left: 2%;
  height: 12rem;
  z-index: 50;
  margin-top: 0px;
  margin-bottom: 0px;
}
.homelist {
  // background-color: #0F1A29;
  background: var(--panel_color);
  border-radius: 10px;
  margin: 10px 2%;
  padding: 10px 0;
  margin-top: 0px;
}
.header ::v-deep .Menu .van-grid-item__content {
  padding: 5px;
  display: flex;
}

.header ::v-deep .Menu .van-grid-item__content div:first-child {
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header ::v-deep .Menu .van-grid-item__content div:last-child {
  margin-top: 0px;
  text-align: center;
}

.content_footer ::v-deep .van-cell::after {
  display: none;
}

.Menu2item2 {
  display: flex;
  height: 105px;
  width: 50%;
  padding: 5px;
  padding-top: 15px;
  text-align: left;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 14px;
  border-radius: 10px;
}

.Menu2item3 {
}
.tool::before {
  content: "";
  display: block;
}

.tool {
  border-radius: 10px;
  overflow-x: auto;
  z-index: 199;
  margin: 10px auto;
  text-align: center;
  background-color: var(--panel_color);
  width: 95%;
  padding: 0 5px;
  line-height: 22px;

  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
    border-radius: 0px;
    border: 0px solid transparent;
    background-clip: padding-box;
  }
  .tool_item{
    display: inline-block;
    background: var(--panel_color);
    border-radius: 16px;
    width: 220px;
    padding: 15px;
    margin: 15px 10px 15px 0;
    .t_img{
      height: 55px;
      width: 55px;
      margin-right: 8px;
    }
    .t_name{
      font-size: 18px;
      font-weight: 600;
    }
    .t_text{
      font-size: 16px;
      font-weight: 400;
    }
  }

  .tool_item2{
    display: inline-block;
    text-align: center;
    background: var(--panel_color3);
    border-radius: 16px;
    width: 110px;
    padding: 15px;
    margin: 15px 5px 15px 5px;
    .t_img{
      height: 45px;
      width: 45px;
      margin-right: 8px;
    }
    .t_name{
      font-size: 15px;
      font-weight: 600;
    }
    .t_text{
      font-size: 15px;
      font-weight: 400;
    }
  }
}

.zh-CN ::v-deep .van-grid-item__icon-wrapper,
.zh-HK ::v-deep .van-grid-item__icon-wrapper {
  word-spacing: 5px;
}

.MemberList ::v-deep .van-tabs__wrap {
  height: 38px;
}

.MemberList ::v-deep .van-tabs__nav--card {
  margin: 0;
}

.MemberList ::v-deep .swipe-item_box {
  display: flex;
  align-items: center;
  padding: 10px 10px !important;
}

.swipe-item_box ::v-deep .swipe-item_info_title {
  margin-left: 15px;
  margin-top: 6px;
  height: 18px;
  font-size: 13px !important;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swipe-item_box ::v-deep .swipe-item_info_title span {
  color: #ff93a4;
}

.swipe-item_box ::v-deep .swipe-item_info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  overflow: hidden;
}

.swipe-item_box ::v-deep .swipe-item_info_details {
  display: flex;
  margin-top: 4px;
  align-items: flex-start;
  border-bottom: 1px solid #d0d0d0;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_state {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 12px;
  transform: scale(0.8);
  white-space: nowrap;
}
.swipe-item_box ::v-deep .swipe-item_info_details .item_state > p {
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.swipe-item_box ::v-deep .swipe-item_info_details .isState {
  color: #4e51bf !important;
}

.swipe-item_box ::v-deep .swipe-item_info_details .isState span {
  background-color: #ff93a4 !important;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_state span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #a0a0a0;
  margin-right: 3px;
  flex-shrink: 0;
  margin-top: 2px;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_site {
  color: #666;
  font-size: 12px;
  transform: scale(0.8);
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swipe-item_box ::v-deep .swipe-item_info_details .profit {
  display: flex;
  margin-left: auto;
  align-items: center;
  color: #ff93a4;
}

.swipe-item_box ::v-deep .swipe-item_info_details .profit img {
  margin-right: 2px;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_massage {
  margin-left: auto;
  width: 80px;
  padding: 3px 0;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ------------- */
.MemberList ::v-deep .swipe-item_img_box {
  background: linear-gradient(0deg, #4e51bf, #ff93a4);
  padding: 2px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.MemberList ::v-deep .swipe-item_img_box1 {
  background: gray;
  padding: 2px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.MemberList ::v-deep .swipe-item_img {
  width: 100%;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ffe9ce;
}

.MemberList ::v-deep .swipe-item_img img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.MemberList ::v-deep .van-cell__title {
  display: flex;
}

.MemberList ::v-deep .van-tab__text {
  /*font-size: 18px;*/
  font-weight: bold;
  display: flex;
  align-items: center;
}

.MemberList .van-swipe-item {
  padding: 1px 0;
}

.topItem {
  line-height: 1;
  /*background-color: #151d31;*/
  color:#fff;
}

.topItem .van-cell__left-icon {
  height: 46px;
  width: 46px;
  /*background-color: #fff;*/
  border-radius: 100%;
  overflow: hidden;
  padding: 2px;
}

.TaskHall_info ::v-deep .van-cell__left-icon {
  font-size: 14px;
}

.topItem .van-icon__image {
  width: 100%;
  height: 100%;
}

.topItem .van-cell__label {
  line-height: 1;
  margin-top: 0;
  color:#fff;
  font-size: 14px;
}

.topItem .profit {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 3px 8px;
  font-size: 13px;
  color: #0e1526;
}
.coinitem {
  margin-left: 10px;
  //border-radius: 10px;
  padding-left: 5px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--bc-text-color-3);

  .rate1{
    color: #55D3E4;
  }
  .rate2{
    color: #FF6270;
  }
  //background-color: var(--panel_color3);
}
.topItem .profit img {
  margin-right: 5px;
}
.MyHeader {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 72px;
  height: 72px;
}
.MyHeader_box {
  padding: 2px;
  overflow: hidden;
  margin-left: 10px;
  flex-shrink: 0;
}


.Menu ::v-deep .van-grid-item__content {
  /*background-color: #151d31;*/
  border-radius: 5px;
  color:#cccccc;
  background: transparent none repeat scroll 0% 0%;
}

.Menu ::v-deep .van-grid-item__icon-wrapper {
  margin-right: 15px;
  overflow: hidden;
  flex: 1;
}

.Title {
  /*background-color: #151d31;*/
  /*color: #fff;*/
  margin: 4px 0;
  padding: 7px 25px;
}

.popupBg {
  background: #fff;
  border-radius: 0.8rem;
}

.NoticePopup {
  background-size: contain;
  background-color: #fff;
  height: 450px;
}

.NoticePopup dd {
  font-size: 13.5px;
  overflow: inherit;
  color: #666666;
}

.popup_title {
  display: flex;
  line-height: normal;
  height: 170px;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 40px 0px 0 0;
}

.popup_title div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 26px;
}

.popup_title div span:first-child {
  font-weight: bold;
  color: #e7e7e7;
}

.popup_title div span:last-child {
  color: #e7e7e7;
  letter-spacing: 0.34rem;
  font-size: 22px;
}

.popup_title ::v-deep .letter_s {
  letter-spacing: 0.2rem;
  font-size: 25px !important;
}

.close img {
  width: 36px;
  margin-top: 20px;
}

.van-popup {
  overflow: inherit;
  /* height: 300px; */
}

.content_footer_justify ::v-deep .van-cell__left-icon {
  margin-right: 0;
}

.content_footer_justify ::v-deep .van-cell__left-icon img {
  width: 1.1em;
  height: 1.1em;
}

.content_footer_justify_itemBgc {
  background: linear-gradient(180deg, #4e51bf, #ff93a4);
  padding: 3px;
  border-radius: 16px;
  margin-top: 15px;
}

.van-grid-item {
  padding: 0 !important;
}

.Site ::v-deep .van-nav-bar__text {
  color: #fff;
  font-size: 15px;
  position: relative;
}

.van-nav-bar__text1 ::v-deep .van-nav-bar__text {
  letter-spacing: 0.09rem;
}

.Site ::v-deep .van-nav-bar__text::after {
  content: "";
  position: absolute;
  background: url(../../public/static/images/language_after.png) no-repeat;
  background-size: 100% 100%;
  width: 9px;
  height: 5px;
  top: 9px;
  right: -14px;
}

.Site ::v-deep .van-nav-bar__right {
  padding: 0;
  padding-right: 35px;
}

.TaskHall_info ::v-deep .van-cell__left-icon img {
  width: 11px;
  height: 11px;
}

.two img {
  width: 30px;
  height: 20px !important;
}
.title{
  margin: 0px 2%;
  font-size: 20px;
  color: var(--font_color);
  line-height: 40px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--bc-text-color-3);
}

.show-more {
  display: flex;
  align-items:center;
  justify-content: center;
  border: 1px solid #4b4a4a;

  border-radius: 20px;
  font-size: 6px;
  width: 46px;
  height: 16px;
  color: var(--bc-text-color);
}

.trading {
  margin: 10px 2%;
  padding: 10px 0;
}

.service {
  margin: 10px 2%;
  padding: 10px 0;
  display: flex;
  justify-content: center;

  img {
    position:absolute;
    max-width: 100%;
    max-height: 100%;
    width: auto;
  }

}

.service-content {
  margin-top: 40px;
  height: 208px;
  display: flex;
  justify-content: center;
}
.polygon {
  position: relative;
  height: 89px;
  top: -86px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  background-image: linear-gradient(to bottom, #578851 0%,#000000 100%);
  clip-path: polygon(0% 0%,12% 25%,25% 7%, 34% 5%, 45% 37%,60% 13%, 80% 0%,100% 0%, 100% 100%, 0% 100%);
  //border: 5px solid #f6f6f6;

 }

.overlay {
  height: 89px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  background-image: linear-gradient(to bottom, #64ab4e 0%,#000000 100%);
  clip-path: polygon(0% 0%,12% 25%,25% 7%, 34% 5%, 45% 37%,60% 13%, 80% 0%,100% 0%, 100% 100%, 0% 100%);
}




</style>
