<template>
  <div class="Site PageBox">
    <NavBar :title="$t('bindAccount.default[0]')" :page-number="-1"/>
    <div class="ScrollBox">
          <van-form @submit="onSubmit">
            <div class="box">
              <div style="border:1px solid #707070; border-radius:10px; opacity: 1;padding: 12px; height: 375px;">
                <van-field :border="false"
                        v-model="BankInfo.bank_name"
                        :label="$t('bindAccount.fields2[3]')"
                        :placeholder="$t('bindAccount.placeholder2[3]')"
                           style="border-bottom: 1px solid #757575; line-height: 38px;"
                />
                <van-field :border="false"
                        v-model="BankInfo.name"
                        :label="$t('bindAccount.fields[0]')"
                        :placeholder="$t('bindAccount.placeholder[0]')"
                           style="border-bottom: 1px solid #757575; line-height: 38px;"
                />
                <van-field :border="false"
                        v-model="BankInfo.card_no"
                        :label="$t('bindAccount.fields[2]')"
                        :placeholder="$t('bindAccount.placeholder[2]')"
                           style="border-bottom: 1px solid #757575; line-height: 38px;"
                />
                <van-field :border="false"
                        v-model="BankInfo.phone"
                        :label="$t('bindAccount.fields[1]')"
                        :placeholder="$t('bindAccount.placeholder[1]')"
                           style="border-bottom: 1px solid #757575; line-height: 38px;"
                />
              <!--<van-field required readonly
                         class="input" :border="false"
                         v-model="BankInfo.bank_type"
                         @click="showPicker = true"
                         :label="$t('bindAccount.fields[4]')"
                         :placeholder="$t('bindAccount.placeholder[4]')"
                         style="border-bottom: 1px solid #757575; line-height: 38px;"
              />-->
              </div>

            <div style="text-align: center;margin-top: 20px" v-show="canEdit">
              <van-button style="width:279px;height:42px;" class="gradient-btn">{{$t('bindAccount2[1]')}}</van-button>
            </div>
              <div style="line-height: 20px; margin-top: 20px;">
                  <div style="color:var(--bc-text-color) ">{{$t('bindAccount2[0]')}}</div>
                  <div style="color:var(--bc-text-color)">{{$t('busAuth2[4]')}}</div>
              </div>
            </div>
            <!--绑定账户-->
            <div style="margin: 0px auto;width: 90%;text-align: center;margin-top: 30px">
              <van-button class="gradient-btn"
                          to="/serviceCenter"
              ><span style="color:#FFF">{{$t('bindAccount2[2]')}}</span>
              </van-button>
            </div>

          </van-form>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BindAccount',
  components: {

  },
  props: [],
  data() {
    return {
      showPicker:false,
      tabsActive: 0,
      weixinAcc: '',
      usdttypes:["TRC20","ERC20"
      ],
      showUsdtType: false,
      columns : ['ERC20', 'TRC20', 'OMNI'],
      BankInfo:{},
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      canEdit:true,
      tabsList: [],
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.$Model.GetUserInfo()

    this.BankInfo.coin_type = this.columns[0];
    this.$Model.GetBankCardList(data=>{
      if (data.code==1&&data['data'].length>0){
        this.BankInfo = data['data'][0];
        this.BankInfo['bank_type'] = this.usdttypes[0];
        this.BankInfo['card_no'] = this.BankInfo['card_no'].substring(0,5)+"*****"+this.BankInfo['card_no'].substring(9);
        this.BankInfo['phone'] = this.BankInfo['phone'].substring(0,4)+"****"+this.BankInfo['phone'].substring(7);
        if(this.BankInfo['coin_address']) {
          this.BankInfo['coin_address'] = this.BankInfo['coin_address'].substring(0,6)+"*****"+this.BankInfo['coin_address'].substring(10);
        } else {
          this.BankInfo['coin_address'] = '';
        }

        this.canEdit = false;
      }
      }
    );
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    onConfirm(obj,index){
      console.log(index);
      this.showPicker = false;
      this.BankInfo.bank_type = this.usdttypes[index]
    },
    onSubmit() {
      this.$Model.AddBankCard(this.BankInfo,data=>{
         var msg = "";
         if (data.code==1){
             msg = this.$t('common4[1]');
             this.$router.push("/");
         }else{
             msg = data.code_dec;
         }
          this.$Dialog.Toast(msg);
      });
    },
    onChangeType(obj,value){
      this.BankInfo.coin_type = value;
      this.showUsdtType = false;
    },
  }
}
</script>
<style scoped>
  .PageBox {
 }
  .PageBox .box {
    background-color: var(--panel_color);
      width: 95%;
      padding: 10px 0px 10px 10px;
      font-size: 13px;
      margin-top: 15px;
      border-radius: 10px;
      margin-left: 10px;

  }

  .van-cell>>>.van-cell__title{
      font-size: 16px;
      width: 130px;
  }
  .van-cell >>> .van-field__control {
      color: var(--cell_text) !important;
      padding-left: 7px;
      height: 100%;
  }

  .van-cell >>> .van-field__control::-webkit-input-placeholder {
    color: var(--cell_placeholder) !important;

  }

  .van-cell >>> .van-field__control::-moz-placeholder {
    color: var(--cell_placeholder) !important;

  }

  .van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
      margin: 0;
      border-radius: 8px;
  }

  .van-field{
      font-weight: bold;
  }
  .van-cell {
    background: transparent;
  }

  .btn {
      width: 85%;
      padding: 10px 50px;
      border-radius: 5px;
      background-color: var(--button_bg);
      color: #FFF;
      font-size: 16px;
      text-align: center;
      margin: 15px 30px 30px;
  }
</style>
