<template>
  <div class="Site PageBox">
    <NavBar :title="authTitle" :page-number="-1"/>
    <div class="ScrollBox">
      <van-form class="mt15">
      <div class="panel border-grey">
        <div style="font-size: 18px;font-weight: bold">
          {{$t('common4[2]')}}:
        </div>
        <div style="font-size: 16px;margin-bottom: 10px;margin-top: 10px" v-if="deposit>0">
          {{$t('common4[4]')}} {{parseFloat(deposit).toFixed(2)}} <!--{{coinName}} ≈ {{usdtMoney}}--> USDT
        </div>
        <div style="font-size: 16px;margin-bottom: 10px;margin-top: 10px" v-if="taxAmount>0">
          {{$t('common4[5]')}} {{parseFloat(taxAmount).toFixed(2)}} <!--{{coinName}} ≈ {{usdtTaxMoney}} -->USDT
        </div>
      </div>

        <div class="centerPanel" style="margin-top: 10px">
          <!--充值-->
          <van-button class="gradient-btn" @click="$router.push('/user/mixrecharge')">{{ $t("wallet.default[1]") }}</van-button>
        </div>

        <div class="panel border-grey" style="margin-top: 10px">
        <h4 style="text-align: left;margin-bottom: 10px">{{$t('busauth[0]')}}</h4>
        <div class="Panel border-grey">
          <van-field
              :border="false"
              class="input "
              v-model.trim="postData.username"
              :placeholder="$t('busauth[1]')"
              size="large"
              clearable
              autocomplete="off"
          />
        </div>
        <div class="Panel border-grey">
          <van-field
              :border="false"
              class="input"
              v-model.trim="postData.id"
              :placeholder="$t('busauth[2]')"
              size="large"
              clearable
              autocomplete="off"
          />
        </div>
        <div class="panel border-grey">
          <h4 style="text-align: left;margin-bottom: 10px;margin-top: 30px">{{$t('busauth[3]')}}</h4>
          <div style="text-align: center;margin-top: 20px;margin-bottom: 20px" v-if="deposit>0">

            <van-uploader v-model="postData.screenshots1" max-count="1"
                         :after-read="afterRead">
              <img src="~@/assets/img/skinbitcoin/icon_upload2.png" style="width: 40px;"/>
            <div style="margin-top: 15px"> {{$t('busauth[4]')}}</div>
            </van-uploader>
          </div>
          <div style="text-align: center;margin-top: 20px;margin-bottom: 20px" v-if="deposit>0">
            <van-uploader v-model="postData.screenshots3" max-count="1"
                          :after-read="afterRead">
              <img src="~@/assets/img/skinbitcoin/icon_upload.png" style="width: 40px;"/>
            <div style="margin-top: 15px"> {{$t('busauth[5]')}}</div>
            </van-uploader>
          </div>
          <div style="text-align: center;margin-top: 20px" v-if="taxAmount>0">
            <van-uploader v-model="postData.screenshots2" max-count="1"
                          :after-read="afterRead">
            <img src="~@/assets/img/skinbitcoin/icon_upload2.png" style="width: 40px;"/>
            <div>{{$t('common4[6]')}}</div>
            </van-uploader>
          </div>
        </div>
        </div>
        <div class="centerPanel" style="text-align: center;margin-top: 20px">
          <!--提交-->
          <van-button class="gradient-btn" @click="submitAuth">{{$t('bindAccount.default[1]')}}</van-button>
        </div>
      </van-form>
    </div>
    </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  props: [],
  data() {
    return {
      showCon: false,
      showNotice: false,
      showRecharge:false,
      postData:{
        username: "",
        id: "",
      },
      infoData: '',
      taxAmount:0,
      deposit:0,
      coinName:"USDT",
      coinRate:10,
      busmoney:10,
    }
  },
  computed: {
    authTitle(){
      var title = "";
      if (this.deposit>0){
        title += this.$t('common4[0]')
      }
      if (this.taxAmount>0){
        title += this.$t('shiming[0]')
      }
      return title
    },
    usdtMoney(){
      return parseFloat(this.coinRate*this.deposit).toFixed(2);
    },
    usdtTaxMoney(){
      return parseFloat(this.coinRate*this.taxAmount).toFixed(2);
    },
  },
  watch: {
    "InitData.helpList"(data) {
      // if (!this.$parent.isNotice && data.length>1) this.showNotice = true;
    },
  },
  created() {
    this.$Model.GetUserInfo();
    let aa = this.UserInfo;
    if (this.UserInfo.deposit_amount>0){
      this.deposit = this.UserInfo.deposit_amount;
    }
    if (this.UserInfo.deposit_rate>0){
      this.taxAmount = this.UserInfo.deposit_rate;
    }

    this.$Model.GetRechargeData((data) => {
      let currlist = data.currencylist;

      currlist.forEach(coindata=>{
        if (coindata['name']==this.coinName){
          this.coinRate = coindata['price0'];
        }
      })
    })
	  // let aa=JSON.parse(this.InitData.helpList[0].content)
	  // this.InitData.helpList.map(item=>JSON.parse(item.content))
	console.log("userinfo: "+this.UserInfo);
    // this.busmoney = this.InitData.setting.busmoney;
	// let balance = this.UserInfo.balance;
	// if (balance<this.busmoney){
	//   this.showRecharge = true;
    // }
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    openShow(data) {
      this.showCon = true
      this.infoData = data
    },
    submitAuth(){
      this.$Model.GoShimingAuth(this.postData,data=>{
        var msg = '';
        if (data.code==1){
          msg = this.$t('common4[1]');
        }else if (data.code==0){
          msg = data.code_dec
        }
        this.$Dialog.Toast(msg);
        if (data.code==1){
          this.$router.replace("/");
        }

      });
    },
    afterRead(file) {
      file.status = 'uploading'
      file.message = this.$t('upload[0]')
      this.uploadImgs(file)
    },
    uploadImgs(file) {
      if (!file.file.type.match(/image/)) {
        file.status = "failed";
        file.message = this.$t("upload[1]");
        return;
      }
      console.log(2);
      this.compressImg(file);
    },
    compressImg(file) {
      this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
        let param = new FormData();
        param.append("token", localStorage["Token"]);
        param.append("type", 3);
        param.append("image", image, file.file.name);
        this.$Model.UploadImg(param, (data) => {
          if (data.code == 1) {
            file.message = this.$t("upload[2]");
            file.status = "success";
            file.url = data.url;
          } else {
            file.status = "failed";
            file.message = this.$t("upload[3]");
          }
        });
      });
    }
  }
}
</script>
<style scoped>
  .PageBox {

  }

  .van-nav-bar {

  }
  .van-nav-bar>>>.van-nav-bar__title{
  }

  .PageBox>>>.tool::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -80%;
  }
  .tool {
    border-radius: 10px;

    color: #ffffff;
    height: 120px;
    box-shadow: 0 1px 3px #dbdbdbdb;
    overflow: hidden;
    z-index: 99;
    padding: 0 5px;
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .panel {
    padding: 4px 10px;
    background-color: var(--panel_color);
    border-radius: 10px;
  }

.Content>>>img{
  max-width: 100%;
}
.van-cell__title{
  font-size: 14px;
  color: #000;
}

/* .van-nav-bar>>>.van-icon{
	color:#fff;
} */

.ScrollBox{
	padding:0 15px;
}
.van-field{
  border-radius: 10px;
}
.ScrollBox>>>.van-cell{
	padding:0px 5px;
	border-bottom: 1px solid #D9D9D9;
}
.ScrollBox>>>.van-cell:last-child{
	border-bottom: 0px solid #D9D9D9;
}
.ScrollBox>>>.van-cell::after{
	display: none;
}
.ScrollBox>>>.van-icon-chat-o::before{
	content: '';
	border-radius: 50%;
	background: #B3B5FC;
	width:8px;
	height: 8px;
}
.ScrollBox>>>.van-icon-chat-o{
	display: flex;
	align-items: center;
	justify-content: center;
}
.ScrollBox>>>.van-cell__title{
	font-size: 16px;
	font-family: ui-serif;
}

.ScrollBox>>>h3{
	font-size: 1.05rem;
	font-family: emoji;
}
.van-cell>>>.van-icon{
	font-size: 18px;
	color:#333;
}
.PageBox>>>.van-popup__close-icon--top-right{
	color:#333;
	top:20px;
	right: 20px;
}
.PageBox>>>.Content{
	margin-top:40px;
	padding:0 20px;
}
.PageBox>>>.Content p{
	font-size: 13px;
	line-height: 1.7;
	font-family: 'vant-icon';
	font-weight: 400;
	color: #666666;
}
  .btn {
    padding: 10px 50px;
    border-radius: 5px;
    background-color: var(--button_bg);
    color: #FFFEFF;
    font-size: 18px;
    text-align: center;
    margin: 15px 10px 30px;
  }
</style>
